<template>
  <div class="charts">
    <div class="content">
      <div class="row items-center justify-between" style="padding: 0 4px;">
        <div class="price">{{ $t('当前价格') }}：<span>{{ cPrice }} U</span></div>
        <div class="zhangfu">
          <div>{{ $t('涨幅') }}：{{ zhangfu.toFixed(4) }}%</div>
          <img src="@/assets/img/base/zhang.png" class="zhangfu-icon" v-if="zhangfu > 0" />
          <img src="@/assets/img/base/die.png" class="zhangfu-icon" v-if="zhangfu < 0" />
        </div>
      </div>
      <div id="echarts" class="echarts"></div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import * as echarts from 'echarts'
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {},
  setup() {
    return {
      dateList: ref([]),
      priceList: ref([]),
      cPrice: ref(0),
      myChart: null,
      zhangfu: ref(0)
    }
  },
  mounted() {
    this.getKline()
    // this.geLineChg()
  },
  methods: {
    getKline() {
      let _this = this
      _this.$request.post(
        "api/Kline/List",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          data.forEach(item => {
            if (item) {
              _this.dateList.push(item.kdate)
              _this.priceList.push(item.price)
            }
          })
          _this.cPrice = _this.priceList[_this.priceList.length - 1]
          if(_this.priceList.length > 1) {
            let prevPrice = _this.priceList[_this.priceList.length - 2]
            _this.zhangfu = (_this.cPrice - prevPrice) / prevPrice
          } else {
            _this.zhangfu = 0
          }
          _this.initEcharts()
        }
      )
    },
    geLineChg() {
      let _this = this
      _this.$request.post(
        "api/Kline/GeLineChg",
        {},
        (res) => {
          _this.zhangfu = res.data.data
        }
      )
    },
    initEcharts() {
      let _this = this
      this.myChart = echarts.init(document.getElementById('echarts'), 'dark')
      let option = {
        backgroundColor: "#2f1d51",
        grid: {
          left: "2%",
          right: "2%",
          top: "5%",
          bottom: "5%",
          containLabel: true
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: _this.dateList
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: _this.priceList,
            type: 'line',
            areaStyle: {},
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#f56ef9' // 0% 处的颜色
                }, {
                  offset: 1, color: '#8a6dff' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          }
        ]
      };
      this.myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.charts {
  border-radius: 10px;
  background-color: #2f1d51;
}

.content {
  padding: 10px 10px 0;
}

.echarts {
  margin-top: 20px;
  width: 100%;
  height: 250px;
}

.price {
  text-align: center;
  color: #fff;
  font-size: 15px;
}

.price span {
  color: #e56efa;
}

.zhangfu {
  color: #e56efa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.zhangfu-icon {
  margin: 0 0 2px 4px;
  width: 12px;
  vertical-align: middle;
}
</style>